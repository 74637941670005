/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@1.11.3/dist/jquery.min.js
 * - /npm/jquery.scrollex@0.2.1/jquery.scrollex.min.js
 * - /npm/jquery.scrolly@0.0.1/jquery.scrolly.min.js
 * - /gh/ajlkn/skel@3.0.1/dist/skel.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
